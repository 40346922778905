var render = function () {
  var _vm$jadwaladmindata, _vm$jadwaladmindata$p;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('section', [_c('b-card', {
    attrs: {
      "no-body": ""
    }
  }, [_c('b-card-body', [_c('b-card-title', [_c('b-row', [_c('b-col', [_c('div', [_vm._v("Ranking : " + _vm._s((_vm$jadwaladmindata = _vm.jadwaladmindata) === null || _vm$jadwaladmindata === void 0 ? void 0 : (_vm$jadwaladmindata$p = _vm$jadwaladmindata.paket) === null || _vm$jadwaladmindata$p === void 0 ? void 0 : _vm$jadwaladmindata$p.name))])])], 1)], 1), _c('b-card-text', {
    staticClass: "blog-content-truncate"
  }, [_c('div', [_c('b-table-simple', {
    attrs: {
      "outlined": "",
      "bordered": "",
      "hover": "",
      "small": "",
      "caption-top": "",
      "responsive": ""
    }
  }, [_c('b-thead', {
    attrs: {
      "head-variant": "light"
    }
  }, [_c('b-tr', [_c('b-th', {
    attrs: {
      "rowspan": "3"
    }
  }, [_vm._v("Ranking")]), _c('b-th', {
    attrs: {
      "rowspan": "3"
    }
  }, [_vm._v("Nama")]), _c('b-th', {
    attrs: {
      "rowspan": "3"
    }
  }, [_vm._v("Sekolah")]), _c('b-th', {
    attrs: {
      "rowspan": "3"
    }
  }, [_vm._v("Total Skor")]), _c('b-th', [_vm._v("Rasionalisasi")])], 1)], 1), _c('b-tbody', _vm._l(_vm.rasio, function (hasil, index) {
    return _c('b-tr', {
      key: index
    }, [_c('b-td', [_vm._v(" " + _vm._s(hasil.ranking.ke) + " ")]), _c('b-td', [_vm._v(" " + _vm._s(hasil.name) + " ")]), _c('b-td', [!hasil || !hasil.school_origin ? _c('i', {
      staticClass: "text-danger"
    }, [_vm._v("Sekolah tidak ditemukan")]) : _c('span', {
      staticClass: "text-dark"
    }, [_vm._v(" " + _vm._s(hasil.school_origin.nama_sekolah) + " ")])]), _c('b-td', [_vm._v(" " + _vm._s(hasil.jumlah_nilai.toFixed(2)) + " ")]), _c('b-td', [_c('feather-icon', {
      staticClass: "cursor-pointer",
      attrs: {
        "icon": "EyeIcon",
        "title": "Hasil Rasionalisai",
        "size": "24"
      },
      on: {
        "click": function click($event) {
          return _vm.viewRasionalisasi(hasil);
        }
      }
    })], 1)], 1);
  }), 1), _c('b-tfoot')], 1)], 1)])], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }